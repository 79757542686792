<template>
  <v-container fluid class="content-wrapper" style="overflow: hidden">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body ml-1">
          <!-- Products sell and New Orders -->
          <div class="row match-height">
            <div class="col-12 mb-2">
              <div class="d-flex flex-row justify-start align-center">
                <h4 class="fw-600 mb-0">
                  {{ $t("SigninPromotion.create_country_prices") }}
                </h4>
                <v-btn
                  icon
                  class="ml-3"
                  @click="initAll()"
                  :class="loadingData && 'animate__spining'"
                  :disabled="loadingData"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
              <v-skeleton-loader v-if="loadingData" type="heading"></v-skeleton-loader>
              <h6 v-else>{{ $t("SigninPromotion.create_country_prices_label") }}</h6>
            </div>
            <hr />

            <div v-if="loadingData" class="d-flex flex-column" style="width: 100%">
              <v-divider />
              <v-skeleton-loader
                type="list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
              ></v-skeleton-loader>
            </div>

            <div v-if="!loadingData" class="col-12 mt-2">
              <span class="border-bottom"></span>
            </div>

            <div v-if="!loadingData" class="col-12 mt-2">
              <h5 class="fw-600">{{ $t("SigninPromotion.create_country_detail") }}</h5>
            </div>

            <div v-if="!loadingData" class="col-md-12 p-md-3">
              <div class="content-body">
                <section id="timeline" class="timeline-left timeline-wrapper">
                  <ul class="timeline">
                    <li class="timeline-line"></li>
                    <!-- 1 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">1</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_country_country") }}
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <ul id="accordion" class="accordion card-accordion">
                              <li
                                v-for="(item, index) in itemsCountry"
                                :key="item.value"
                                :class="index !== itemsCountry.length - 1 && 'li-bottom'"
                              >
                                <div v-if="item.children" class="link">
                                  <v-expansion-panels flat>
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            :id="item.value"
                                            name="country"
                                            type="checkbox"
                                            class="custom-control-input"
                                            :value="item.value"
                                            :checked="item.check"
                                            required
                                            @change="checkAllContinent(item)"
                                          />
                                          <label
                                            class="custom-control-label"
                                            :for="item.value"
                                            >{{ item.name }}</label
                                          >
                                        </div>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <div class="row my-2">
                                          <div
                                            v-for="el in item.children"
                                            :key="el.value"
                                            class="col-md-4 col-sm-6 col-12"
                                          >
                                            <div class="custom-control custom-checkbox">
                                              <input
                                                :id="el.value"
                                                name="childcountry"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :value="el.value"
                                                :checked="el.check"
                                                required
                                                @change="checkSomeContinent(item, el)"
                                              />
                                              <label
                                                class="custom-control-label"
                                                :for="el.value"
                                                >{{ el.name }}</label
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </div>
                                <div v-else class="link">
                                  <div class="custom-control custom-checkbox">
                                    <input
                                      :id="item.value"
                                      name="country"
                                      type="checkbox"
                                      class="custom-control-input"
                                      :value="item.value"
                                      :checked="item.check"
                                      required
                                      @change="checkSomeContinent(item, null)"
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="item.value"
                                      >{{ item.name }}</label
                                    >
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 2 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">2</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">{{ $t("SigninPromotion.discount") }}</h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="d-flex">
                              <div class="align-self-center">
                                <input
                                  type="number"
                                  min="0"
                                  @keypress="isNumber($event)"
                                  id="discount"
                                  class="form-control"
                                  placeholder="30"
                                  name
                                  v-model="discount"
                                  @focus="getfocus('discount')"
                                  @change="getfocus('discount')"
                                  @mouseleave="mouseOver('discount')"
                                />
                              </div>
                              <div class="mx-1">
                                <h3>%</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 3 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">3</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_country_refrain") }}
                          </h4>
                          <h6>
                            {{ $t("SigninPromotion.create_country_refrain_label") }}
                          </h6>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group">
                              <div class="controls pl-2">
                                <div class="row">
                                  <div class="col-xl-4 col-md-6 col-12">
                                    <div class="cal1" id="cal1">
                                      <v-date-picker
                                        v-model="selectedDiscountDates"
                                        color="#1e9ff2"
                                        show-adjacent-months
                                        :show-current="showMonthCurrent"
                                        multiple
                                        @input="selectDiscountDatesFunc()"
                                      ></v-date-picker>
                                    </div>
                                  </div>
                                  <!-- <div class="col-xl-4 col-md-6 col-12">
                                    <div class="cal1" id="cal2">
                                      <v-date-picker
                                        v-model="selectedDiscountDates"
                                        color="#1e9ff2"
                                        show-adjacent-months
                                        :show-current="showMonthNext"
                                        multiple
                                      ></v-date-picker>
                                    </div>
                                  </div>-->
                                </div>
                                <div class="card border-secondary">
                                  <div class="card-content">
                                    <div class="card-body">
                                      <h6 class="pb-1">
                                        <b>
                                          {{
                                            $t(
                                              "SigninPromotion.create_country_discount_offer"
                                            )
                                          }}
                                          :
                                        </b>
                                      </h6>
                                      <h6
                                        v-for="item in selectedDiscountDates"
                                        :key="item"
                                        class="pb-1"
                                      >
                                        {{ moment(item).format("DD MMM YYYY") }}
                                      </h6>
                                      <a @click="selectedDiscountDates = []">
                                        {{ $t("SigninPromotion.btn_delete_all") }}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
            </div>

            <div v-if="!loadingData" class="col-md-12 py-3 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <v-btn
                    outlined
                    class="mr-3 btn-warning"
                    style="min-height: 2.625rem; min-width: 7.5rem"
                    dark
                    @click="gobackFunc()"
                    >{{ $t("SigninRoomDetails.btn_back") }}</v-btn
                  >
                  <v-btn
                    outlined
                    class="btn-info"
                    style="min-height: 2.625rem; min-width: 7.5rem"
                    dark
                    @click="savePromotionFunc()"
                    >{{ $t("SigninRoomDetails.btn_save") }}</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninPromotionTypeNationScreen",
  metaInfo: {
    title: "Promotion",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    promotionTypeID: "",
    promotionID: "",
    value_element: "",
    propertyData: null,
    itemsCountry: [],
    itemsOldCountry: [],
    discount: null,
    showMonthCurrent: "",
    showMonthNext: "",
    selectedDiscountDates: [],
    selectedOldDiscountDates: [],
    oldPromotionData: {},
    oldToUpdate: false,
    loadingData: false,
    promotionDetail: {},
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.promotionTypeID = self.$route.params.promotionTypeId;
    self.promotionID = self.$route.params.promotionId;
    self.initAll();
  },
  watch: {},
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    gobackFunc() {
      this.loading = true;
      // if (window.history?.length > 2) {
      //   EventBus.$emit("goBackPage");
      // } else {
      const link = {
        name: "SigninPromotionScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      // }
    },
    initAll() {
      const self = this;
      self.initCountry();
      self.initPropertyData();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var haveData = false;
      self.loadingData = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        var tempDiscountData = [];
        temp?.promotion?.map((el) => {
          if (el?._id == self.promotionID) {
            self.oldPromotionData = el;
            self.promotionDetail = el;
            haveData = true;
            self.discount = el?.discount_percent || null;
            var tempOldCountry = [];
            self.itemsCountry?.map((element) => {
              if (element?.children?.length) {
                element?.children?.map((ele) => {
                  const tempChild = el?.limit_country?.find((p) => p == ele.value);
                  if (tempChild) {
                    element.check = true;
                    ele.check = true;
                    tempOldCountry.push(ele.value);
                  }
                });
              } else {
                const tempCountry = el?.limit_country?.find((p) => p == element.value);
                if (tempCountry) {
                  element.check = true;
                  tempOldCountry.push(element.value);
                }
              }
            });
            tempOldCountry.sort();
            self.itemsOldCountry = tempOldCountry;
            if (el?.inactive_dates?.length) {
              el?.inactive_dates?.map((ele) => {
                tempDiscountData.push(moment(ele).format("YYYY-MM-DD"));
              });
            }
          }
        });
        self.selectedOldDiscountDates = tempDiscountData.sort();
        self.selectedDiscountDates = tempDiscountData.sort();
        if (!haveData) {
          self.showMonthCurrent = moment().format("YYYY-MM-DD");
          self.showMonthNext = moment().add(1, "M").format("YYYY-MM-DD");
        }
        self.loadingData = false;
      }
    },
    initCountry() {
      const self = this;
      var temp = [];
      temp = [
        {
          name: self.$t("SigninPromotionCountry.switzerland"),
          value: "switzerland",
          check: false,
          children: null,
        },
        {
          name: self.$t("SigninPromotionCountry.australia"),
          value: "australia",
          check: false,
          children: null,
        },
        {
          name: self.$t("SigninPromotionCountry.america"),
          value: "america",
          check: false,
          children: null,
        },
        {
          name: self.$t("SigninPromotionCountry.china"),
          value: "china",
          check: false,
          children: null,
        },
        {
          name: self.$t("SigninPromotionCountry.domestic"),
          value: "domestic",
          check: false,
          children: null,
        },
        {
          name: self.$t("SigninPromotionCountry.middleeast"),
          value: "middleeast",
          check: false,
          children: [
            {
              name: self.$t("SigninPromotionCountry.iran"),
              value: "iran",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.israel"),
              value: "israel",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.oman"),
              value: "oman",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.qatar"),
              value: "qatar",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.saudiarabia"),
              value: "saudiarabia",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.turkey"),
              value: "turkey",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.unitedarabemirates"),
              value: "unitedarabemirates",
              check: false,
            },
          ],
        },
        {
          name: self.$t("SigninPromotionCountry.northamerica"),
          value: "northamerica",
          check: false,
          children: [
            {
              name: self.$t("SigninPromotionCountry.kazakhstan"),
              value: "kazakhstan",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.mexico"),
              value: "mexico",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.trinidadandtobed"),
              value: "trinidadandtobed",
              check: false,
            },
          ],
        },
        {
          name: self.$t("SigninPromotionCountry.asia"),
          value: "asia",
          check: false,
          children: [
            {
              name: self.$t("SigninPromotionCountry.fromaboard"),
              value: "fromaboard",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.sili"),
              value: "sili",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.japan"),
              value: "japan",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.newzealand"),
              value: "newzealand",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.brazil"),
              value: "brazil",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.pakistan"),
              value: "pakistan",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.philippines"),
              value: "philippines",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.malaysia"),
              value: "malaysia",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.ukraine"),
              value: "ukraine",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.russia"),
              value: "russia",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.singapore"),
              value: "singapore",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.argentina"),
              value: "argentina",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.india"),
              value: "india",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.indonesia"),
              value: "indonesia",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.hongkong"),
              value: "hongkong",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.southkorea"),
              value: "southkorea",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.belarus"),
              value: "belarus",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.peru"),
              value: "peru",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.vietnam"),
              value: "vietnam",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.southafrica"),
              value: "southafrica",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.algeria"),
              value: "algeria",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.colombia"),
              value: "colombia",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.taiwan"),
              value: "taiwan",
              check: false,
            },
            {
              name: self.$t("SigninPromotionCountry.thailand"),
              value: "thailand",
              check: false,
            },
          ],
        },
      ];
      self.itemsCountry = temp;
    },
    checkAllContinent(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
      if (item.check) item.children.map((el) => (el.check = true));
      else item.children.map((el) => (el.check = false));
    },
    checkSomeContinent(item, child) {
      const self = this;
      if (child) {
        if (child?.check) child.check = !child.check;
        else child.check = true;
      }
      if (item?.children?.length) {
        const checkSome = item.children.find((p) => p.check);
        // console.log(checkSome);
        if (checkSome) item.check = true;
        else item.check = false;
      } else {
        if (item.check) item.check = !item.check;
        else item.check = true;
      }
    },
    selectDiscountDatesFunc() {
      const self = this;
      self.selectedDiscountDates.sort();
    },
    async savePromotionFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempCountry = [];
      self.itemsCountry?.map((el) => {
        if (el?.children?.length) {
          el?.children?.map((ele) => {
            if (ele.check) tempCountry.push(ele.value);
          });
        } else {
          if (el.check) tempCountry.push(el.value);
        }
      });
      const obj = {
        promotion_name: self.$t("SigninPromotion.create_country_promotion"),
        rate_plan: null,
        rooms: null,
        discount_percent: Number(self.discount),
        stay: {
          start_date: null,
          end_date: null,
          limit_day: null,
        },
        booking: {
          start_date: null,
          end_date: null,
          start_time: null,
          end_time: null,
        },
        limit_number: null,
        limit_country: tempCountry,
        inactive_dates: self.selectedDiscountDates.sort(),
        minimum_stay: null,
        promotion: self.promotionTypeID,
        status:
          self.promotionDetail.status == "expired"
            ? "active"
            : self.promotionDetail.status,
      };

      // console.log("obj: ", obj);

      if (
        obj?.discount_percent &&
        obj?.limit_country?.length &&
        // obj?.inactive_dates?.length &&
        obj?.promotion
      ) {
        try {
          var res = null;
          if (self.promotionID) {
            res = await self.axios.put(
              process.env.VUE_APP_API +
                `/property/promotion?promotion_id=${
                  self?.promotionID || self?.$route?.params?.promotionId
                }`,
              obj,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          } else {
            res = await self.axios.post(
              process.env.VUE_APP_API +
                `/property/promotion?property_id=${
                  self?.propertyID || self?.$route?.params?.propertyId
                }`,
              obj,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          }
          if (res.status == 200) {
            EventBus.$emit("loadingtillend");
            self.oldToUpdate = true;
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          ).then(() => self.gobackFunc());
        }
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    checkChangeData() {
      EventBus.$emit("loadingtillend");
      const self = this;
      var cantgonextroute = true;
      var tempCountry = [];
      self.itemsCountry?.map((el) => {
        if (el?.children?.length) {
          el?.children?.map((ele) => {
            if (ele.check) tempCountry.push(ele.value);
          });
        } else {
          if (el.check) tempCountry.push(el.value);
        }
      });
      tempCountry.sort();

      var isSameCountry =
        self.itemsOldCountry.length == tempCountry.length &&
        self.itemsOldCountry.every(function (element, index) {
          return element === tempCountry[index];
        });

      var isSameDates =
        self.selectedOldDiscountDates.length == self.selectedDiscountDates.length &&
        self.selectedOldDiscountDates.every(function (element, index) {
          return element === self.selectedDiscountDates[index];
        });

      if (self.promotionID) {
        if (self.oldToUpdate) {
          cantgonextroute = false;
        } else if (
          self.discount !== self?.oldPromotionData?.discount_percent ||
          !isSameCountry ||
          !isSameDates
        ) {
          cantgonextroute = true;
        } else {
          cantgonextroute = false;
        }
      } else {
        if (self.oldToUpdate) {
          cantgonextroute = false;
        } else if (
          self?.discount ||
          tempCountry?.length ||
          self.selectedDiscountDates?.length
        ) {
          cantgonextroute = true;
        } else cantgonextroute = false;
      }
      return cantgonextroute;
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/timeline.css";
@import "../../../styles/signin/promotion.css";

.v-expansion-panel-header {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
